import PropTypes from 'prop-types';
import React from 'react';

DangerAlert.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
};

DangerAlert.defaultProps = {
  title: 'There is an error',
  className: ''
};

export function DangerAlert({ title, className }) {
  return <span className={`block text-paragraph-small text-red-400 ${className}`}>{title}</span>;
}

SuccessAlert.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
};

SuccessAlert.defaultProps = {
  title: 'There is an error',
  className: ''
};

export function SuccessAlert({ title, className }) {
  return (
    <div className={`rounded border border-green-500 bg-green-100 p-4 text-green-700 ${className}`}>
      <p>{title}</p>
    </div>
  );
}
